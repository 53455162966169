var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.open
    ? _c(
        "vxe-modal",
        {
          ref: "manyVehicleMonitorRef",
          attrs: {
            width: "800px",
            height: "auto",
            title: _vm.formTitle,
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: true,
            "esc-closable": true,
            position: { top: "10%" },
            remember: "",
            transfer: "",
          },
          on: {
            close: function ($event) {
              _vm.open = false
            },
          },
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              staticClass: "send-message",
              attrs: { model: _vm.form },
            },
            [
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { md: 24, sm: 24 } }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("消息类型："),
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(
                        _vm._s(_vm.msgType.typeName) +
                          "（" +
                          _vm._s(_vm.msgType.typeCode) +
                          "）"
                      ),
                    ]),
                  ]),
                  _c("a-col", { attrs: { md: 24, sm: 24 } }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("消息内容："),
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.template ? _vm.template.templateContent : ""
                          )
                      ),
                    ]),
                  ]),
                  _c("a-col", { attrs: { md: 24, sm: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "param-list" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("span", { staticClass: "col header" }, [
                            _vm._v("变量名"),
                          ]),
                          _c("span", { staticClass: "col header" }, [
                            _vm._v("变量值"),
                          ]),
                        ]),
                        _vm._l(_vm.form.paramsList, function (item, index) {
                          return _c("div", { key: index, staticClass: "row" }, [
                            _c("div", { staticClass: "child-row" }, [
                              _c("span", { staticClass: "col" }, [
                                _c("span", { staticClass: "item-content" }, [
                                  _vm._v(_vm._s(item.paramName)),
                                ]),
                              ]),
                              _c(
                                "span",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        prop:
                                          "paramsList[" +
                                          index +
                                          "].paramValue",
                                        rules: [
                                          {
                                            required: true,
                                            trigger: "change",
                                            message: "请输入变量值",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: { placeholder: "请输入变量值" },
                                        model: {
                                          value:
                                            _vm.form.paramsList[index]
                                              .paramValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.paramsList[index],
                                              "paramValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.paramsList[index].paramValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bottom-control" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 发送 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }